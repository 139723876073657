<template>
  <v-container fluid class="pt-0">
    <v-row dense v-if="myPlayer.status === 'Expired'">
      <v-col cols="12" class="title">
        Renew Your Membership
      </v-col>
      <v-col cols="12" class="subheading">
        Your membership is currently expired.
        To renew, click the membership level you would like below.
      </v-col>
    </v-row>
    <v-row dense v-if="isInactive">
      <v-col cols="12" class="title">
        Activate Your membership
      </v-col>
      <v-col cols="12" class="subheading">
        Select your membership level below.
      </v-col>
    </v-row>
    <v-card>
      <v-toolbar dense flat color="color2 color2Text--text">
        <v-toolbar-title>
          Choose your level
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-row dense>
          <v-col cols="12" sm="6" :lg="myProgram.levels.length > 2 ? 3 : 6" v-for="level in myProgram.levels" :key="level.id">
            <div v-if="false" class="text-center text-h5">Your Current Level</div>
            <v-hover>
              <div slot-scope="{ hover }" class="fill-height">
                <level-card
                  :level="level"
                  @selected="selectLevel"
                  :disabled="disableLevel(level)"
                  :selected="(allowHover && hover)"
                  :actionText="getActionText(level)"
                ></level-card>
              </div>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import CartItem from '@/classes/CartItem'
import * as actions from '@/store/ActionTypes'
import LevelCard from '@/components/Membership/LevelCard'

export default {
  props: ['program', 'player', 'active', 'isNew', 'invite', 'preselected', 'loading'],
  data () {
    return {
      selectedLevel: null,
      inviteCode: null,
      loadingx: true,
      verifiedCode: false,
      errorCodes: [],
      go: false,
      more: []
    }
  },
  computed: {
    myProgram () {
      return this.program || { levels: [] }
    },
    myPlayer () {
      return this.player || { status: 'Inactive' }
    },
    isInactive () {
      return this.myPlayer.status === 'Inactive'
    },
    isExpired () {
      return this.myPlayer.status === 'Expired'
    },
    cartItem () {
      if (!this.selectedLevel) return null
      const item = new CartItem()
      item.newMembership(this.program, this.selectedLevel, this.player)
      return item
    },
    currentLevel () {
      return (this.player && this.player.levelId && this.program && this.program.levels)
        ? this.program.levels.find(f => f.id === this.player.levelId) : null
    },
    allowHover () {
      return this.isExpired || !this.currentLevel
    },
    test () {
      return this.myProgram.levels.map(m => this.disableLevel(m))
    }
  },
  methods: {
    isCurrent (level) {
      return !this.isExpired && this.currentLevel && level.id === this.currentLevel.id
    },
    getActionText (level) {
      if (!this.currentLevel) return 'Activate'
      if (this.isExpired) return 'Renew'
      if (level.id === this.currentLevel.id) return 'Extend Your Membership'
      if (this.currentLevel) {
        return level.id === this.currentLevel.id ? 'Current level' : level.price < this.currentLevel.price ? 'NA' : 'Upgrade'
      }
      return this.player && this.player.status === 'Expired' ? 'Renew' : 'Activate'
    },
    disableLevel (level) {
      return level.disabled // || (!this.isExpired && this.currentLevel && this.currentLevel.price >= level.price)
    },
    selectLevel (level) {
      if (this.disableLevel(level)) return
      this.select(level)
      this.addToCart()
    },
    select (level) {
      this.selectedLevel = level
    },
    addToCart () {
      if (this.cartItem) {
        this.$store.dispatch(actions.ADD_CART_ITEM, this.cartItem)
        this.$router.push({ name: 'checkout' })
      }
    },
    checkLevel () {
      console.log('check level')
      if (this.player && this.player.levelId && !this.currentLevel && !this.isInactive && !this.isExpired) {
        this.$emit('add-level', this.player.levelId)
      }
    }
  },
  watch: {
    active: function (v) {
      this.checkLevel()
      // this.$vuetify.goTo(0)
    }
  },
  mounted () {
    this.active && this.checkLevel()
  },
  components: {
    LevelCard
  }
}
</script>

<style scoped>
  .outlined-card {
    border: 2px solid var(--v-color3-base) !important
  }
</style>
